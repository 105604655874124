import { Container, Grid, Typography } from "@mui/material";
import FacebookIcon from "assets/images/facebook-white.svg";
import InstagramIcon from "assets/images/instagram-white.svg";
import "./styles.scss";

const Footer = () => {
  return (
    <Grid className="footer-container">
      <Container>
        <Grid container>
          <Grid item md={12} sm={12} xs={12} className="footer-item-logo">
            <img src={require("assets/images/logo.png")} alt="" />
          </Grid>
          <Grid item md={12} sm={12} xs={12} className="footer-item-social-link">
            <a
              href="https://www.facebook.com/bingsoo.au"
              target="_blank"
              rel="noreferrer"
            >
              <img width={24} height={24} src={FacebookIcon} alt="" />
            </a>
            <a
              href="https://www.instagram.com/bingsoo.au"
              target="_blank"
              rel="noreferrer"
            >
              <img width={24} height={24} src={InstagramIcon} alt="" />
            </a>
          </Grid>
          <Grid item md={12} sm={12} xs={12} className="footer-item-copy-right">
            <Typography className="copy-right">
              {`Copyright © ${new Date().getFullYear()} BINGSOO`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Footer;
