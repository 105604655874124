import {
  Button,
  Card,
  CardContent,
  Drawer,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import "./styles.scss";
import { Cart } from "models/cart";
import CloseIcon from "@mui/icons-material/Close";
import CartItem from "./components/CartItem";
import Utils from "utils";

interface CartProps {
  isCartOpen: boolean;
  isCheckOut: boolean;
  amount?: number;
  cartItems: Cart[];
  onClose?: any;
  handlePayment?: any;
  handleCheckOut?: any;
}

const CartComponent = ({
  isCartOpen,
  isCheckOut,
  amount,
  cartItems,
  onClose,
  handlePayment,
  handleCheckOut,
}: CartProps) => {
  return (
    <Drawer
      className="order-cart-container"
      anchor="right"
      open={isCartOpen}
      onClose={onClose}
      transitionDuration={500}
    >
      <Card className="order-cart-detail">
        <CardContent>
          <Grid display={"flex"} direction={"column"} gap={"16px"}>
            <Grid
              className="order-cart-header"
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Grid display={"flex"} alignItems={"center"}>
                <Typography variant="h6" gutterBottom>
                  Cart Details
                </Typography>
              </Grid>
              <Grid>
                <IconButton onClick={onClose}>
                  <CloseIcon fontSize="medium" />
                </IconButton>
              </Grid>
            </Grid>
            <Grid className="cart-item-list">
              {cartItems.map((item) => (
                <CartItem cartItem={item} />
              ))}
              {isCheckOut && (
                <Grid display={"flex"} gap={"8px"} direction={"column"}>
                  <Typography variant="body1" style={{ fontWeight: 600 }}>
                    Payment method
                  </Typography>
                  <Grid display={"flex"} gap={"8px"}>
                    <Typography
                      style={{
                        padding: "8px",
                        border: "1px solid orange",
                        width: "50px",
                        justifyContent: "center",
                        textAlign: "center",
                        fontWeight: 500,
                        backgroundColor: "red",
                        color: "#fff",
                      }}
                    >
                      Cash
                    </Typography>
                    <Typography
                      style={{
                        padding: "8px",
                        border: "1px solid orange",
                        justifyContent: "center",
                        textAlign: "center",
                        fontWeight: 500,
                      }}
                    >
                      Debit/Credit
                    </Typography>
                  </Grid>
                  <Grid className="payment-button">
                    <Button
                      onClick={handlePayment}
                      fullWidth
                      style={{ height: "48px" }}
                      variant="contained"
                      className="place-to-order-button"
                    >{`Place to order (${Utils.formatCurrency(
                      amount ?? 0
                    )})`}</Button>
                  </Grid>
                </Grid>
              )}
              {!isCheckOut && (
                <Grid className="payment-button">
                  <Button
                    onClick={handleCheckOut}
                    fullWidth
                    style={{ height: "48px" }}
                    variant="contained"
                    className="check-out"
                  >{`Checkout (${Utils.formatCurrency(amount ?? 0)})`}</Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Drawer>
  );
};

export default CartComponent;
