import { combineReducers } from "@reduxjs/toolkit";
import {
  NS_CART,
  NS_CUSTOM_MODAL,
  NS_DATA,
  NS_PRODUCT_DETAIL,
} from "./../constants";
import {
  cartReducer,
  customModalReducer,
  dataReducer,
  productDetailReducer,
} from "./product/slice";
const rootReducer = combineReducers({
  [NS_CART]: cartReducer,
  [NS_DATA]: dataReducer,
  [NS_PRODUCT_DETAIL]: productDetailReducer,
  [NS_CUSTOM_MODAL]: customModalReducer,
});

export default rootReducer;
