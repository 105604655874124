import { PayloadAction, Reducer, createSlice } from "@reduxjs/toolkit";
import {
  CartStateType,
  CustomModalStateType,
  DataStateType,
  ProductDetailStateType,
  initialCartState,
  initialDataState,
  initialProductDetailState,
} from "./state";
import { Cart } from "../../models/cart";
import { ApiResponse } from "../../models/apiResponse";
import {
  NS_CART,
  NS_CUSTOM_MODAL,
  NS_DATA,
  NS_PRODUCT_DETAIL,
} from "../../constants";
import { ProductDetailModel } from "../../models/productDetail";
import { removeItemLocalStorage, setItemLocalStorage } from "storage";

export const cartSlice = createSlice({
  name: NS_CART,
  initialState: initialCartState,
  reducers: {
    openCart: (state: CartStateType, action: PayloadAction<boolean>) => {
      state.isOpenCart = action.payload;
    },
    addTableCode: (state: CartStateType, action: PayloadAction<string>) => {
      state.tableCode = action.payload;
    },
    loadCart: (state: CartStateType, action: PayloadAction<CartStateType>) => {
      state.carts = [...action.payload.carts];
    },
    addItem: (state = initialCartState, action: PayloadAction<Cart>) => {
      const newCarts = [...state.carts, action.payload];
      setItemLocalStorage(state.tableCode, { ...state, carts: newCarts });
      state.carts = newCarts;
    },
    updateItem: (state = initialCartState, action: PayloadAction<Cart>) => {
      const { payload } = action;
      const cartUpdates = state.carts.map((cart) => {
        if (cart.id === payload.id) {
          cart = {
            ...cart,
            quantity: payload.quantity,
            addOns: [...payload.addOns],
            removeOns: [...payload.removeOns],
          };
        }
        return cart;
      });
      setItemLocalStorage(state.tableCode, { ...state, carts: cartUpdates });
      state.carts = [...cartUpdates];
    },
    removeItem: (state = initialCartState, action: PayloadAction<number>) => {
      const cartUpdates = state.carts.filter(
        (item) => item.id !== action.payload
      );
      setItemLocalStorage(state.tableCode, { ...state, carts: cartUpdates });
      state.carts = cartUpdates;
      if (cartUpdates.length === 0) {
        state.isOpenCart = false;
        removeItemLocalStorage(state.tableCode);
      }
    },
    incrementQuantity: (
      state = initialCartState,
      action: PayloadAction<number>
    ) => {
      const { payload } = action;
      const cartUpdates = state.carts.map((cart) => {
        if (cart.id === payload) {
          cart = { ...cart, quantity: cart.quantity + 1 };
        }
        return cart;
      });
      setItemLocalStorage(state.tableCode, { ...state, carts: cartUpdates });
      state.carts = [...cartUpdates];
    },
    decrementQuantity: (
      state = initialCartState,
      action: PayloadAction<number>
    ) => {
      const { payload } = action;
      const cartUpdates = state.carts.map((cart) => {
        if (cart.id === payload) {
          if (cart.quantity > 1) {
            cart = { ...cart, quantity: cart.quantity - 1 };
          }
        }
        return cart;
      });
      setItemLocalStorage(state.tableCode, { ...state, carts: cartUpdates });
      state.carts = [...cartUpdates];
    },
    clearAll: (state: CartStateType) => {
      state.carts = [];
    },
  },
});

export const dataSlice = createSlice({
  name: NS_DATA,
  initialState: initialDataState,
  reducers: {
    fetchDataStart: (state) => {
      state.isLoading = true;
    },
    fetchDataSuccess: (state, action: PayloadAction<ApiResponse>) => {
      state.isLoading = false;
      state.categories = action.payload.data?.menuInfo?.categories ?? [];
      state.products = action.payload.data?.menuInfo?.products ?? [];
      state.originalProducts = action.payload.data?.menuInfo?.products ?? [];
      state.modifiers = action.payload.data?.menuInfo?.modifiers ?? [];
    },
    fetchDataFailure: (state) => {
      state.isLoading = false;
    },
    getProductFilter: (state: DataStateType, action: PayloadAction<string>) => {
      const productFilters =
        state.originalProducts?.filter((x) =>
          x.name.toLowerCase().includes(action.payload.toLowerCase())
        ) ?? [];
      state.products = [...productFilters];
    },
  },
});

export const productDetailSlice = createSlice({
  name: NS_PRODUCT_DETAIL,
  initialState: initialProductDetailState,
  reducers: {
    openProductDetail: (
      state: ProductDetailStateType,
      action: PayloadAction<boolean>
    ) => {
      state.isOpenProductDetail = action.payload;
    },
    setProduct: (state, action: PayloadAction<ProductDetailModel>) => {
      const { payload } = action;
      state.product = payload;
      state.isOpenProductDetail = true;
    },
    incrementQuantityProductDetail: (state) => {
      state.product = {
        ...state.product,
        quantity: state.product.quantity + 1,
      };
    },
    decrementQuantityProductDetail: (state) => {
      if (state.product.quantity === 1) {
        return;
      }
      state.product = {
        ...state.product,
        quantity: state.product.quantity - 1,
      };
    },
    addOn: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      const addOnUpdates = state.product.addOns.map((item) => {
        if (item.id === payload) {
          item = { ...item, isChecked: !item.isChecked };
        }
        return item;
      });
      state.product.addOns = [...addOnUpdates];
    },
    removeOn: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      const removeOnUpdates = state.product.removeOns.map((item) => {
        if (item.id === payload) {
          item = { ...item, isChecked: !item.isChecked };
        }
        return item;
      });
      state.product.removeOns = [...removeOnUpdates];
    },
    instructionOnChange: (state, action: PayloadAction<string>) => {
      state.product = { ...state.product, instruction: action.payload };
    },
  },
});

export const customModalSlice = createSlice({
  name: NS_CUSTOM_MODAL,
  initialState: {
    isOpen: false,
  },
  reducers: {
    openModal: (
      state: CustomModalStateType,
      action: PayloadAction<{ title: string; content: string }>
    ) => {
      state.isOpen = true;
      state.title = action.payload.title;
      state.content = action.payload.content;
    },
    closeModal: (state: CustomModalStateType) => {
      state.isOpen = false;
    },
  },
});

export const {
  openCart,
  addTableCode,
  loadCart,
  addItem,
  updateItem,
  removeItem,
  incrementQuantity,
  decrementQuantity,
  clearAll,
} = cartSlice.actions;
export const cartReducer: Reducer<CartStateType> = cartSlice.reducer;

export const {
  fetchDataStart,
  fetchDataSuccess,
  fetchDataFailure,
  getProductFilter,
} = dataSlice.actions;
export const dataReducer: Reducer<DataStateType> = dataSlice.reducer;

export const {
  openProductDetail,
  setProduct,
  incrementQuantityProductDetail,
  decrementQuantityProductDetail,
  addOn,
  removeOn,
  instructionOnChange,
} = productDetailSlice.actions;

export const productDetailReducer: Reducer<ProductDetailStateType> =
  productDetailSlice.reducer;

export const { openModal, closeModal } = customModalSlice.actions;
export const customModalReducer: Reducer<CustomModalStateType> =
  customModalSlice.reducer;
