import { KEY_ORDER_PAGE } from "constants/index";
import { Cart } from "../models/cart";

class Utils {
  static formatCurrency(num: number) {
    return (
      "$" + (num / 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  }

  static recalculateTotalQuantity(carts: Cart[]): number {
    if (!carts) {
      return 0;
    }
    const totalQuantity: number = carts.reduce(
      (total, item) => total + item.quantity,
      0
    );
    return totalQuantity;
  }

  static recalculateTotalAmount(carts: Cart[]): number {
    if (!carts) {
      return 0;
    }

    const totalAmount: number = carts.reduce(
      (total, item) =>
        total +
        (item.quantity * item.price +
          (item.addOns
            ?.filter((x) => x.isChecked === true)
            .reduce((totalAddOn, x) => totalAddOn + x.price, 0) ?? 0)),
      0
    );
    return totalAmount;
  }

  static getCurrentQuantityByProductId(
    carts: Cart[],
    productId?: number
  ): number {
    const prod = carts.find((x) => x.id === productId);
    if (prod) {
      return prod.quantity;
    }

    return 0;
  }

  static hasKey(str: string): boolean {
    return str === KEY_ORDER_PAGE;
  }
}

export default Utils;
