import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "utils/common";
import { useDispatch } from "react-redux";
import { getProductFilter } from "store/product/slice";
import CancelIcon from "@mui/icons-material/Cancel";
import { useState } from "react";

const SearchText = () => {
  const dispatch = useDispatch();
  const [keyWord, setKeyWord] = useState<string>("");
  const handleOnchange = (value: string) => {
    setKeyWord(value);
    debounce(dispatch(getProductFilter(value)), 600);
  };

  const handleClearAll = () => {
    setKeyWord("");
    debounce(dispatch(getProductFilter("")), 600);
  }

  return (
    <Grid style={{ marginTop: "16px" }}>
      <TextField
        fullWidth
        size="small"
        variant="standard"
        value={keyWord}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={handleClearAll}>
                <CancelIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={(e) => {
          handleOnchange(e.target.value);
        }}
      />
    </Grid>
  );
};

export default SearchText;
