import { Grid, TextareaAutosize, Typography } from "@mui/material";
import Slider from "react-slick";
import { ProductDetailSliderSetting } from "constants/index";
import ExpandCard from "components/common/expandCard";
import QuantityControl from "components/common/quantityControl";
import { useMemo } from "react";
import { ProductDetailModel } from "models/productDetail";

interface ProductDetailItemProps {
  product: ProductDetailModel;
  handleIncrementQuantity?: any;
  handleDecrementQuantity?: any;
  handleAddOnModifier?: any;
  handleRemoveOnModifier?: any;
  instructionOnchange: any;
}

const ImageItems = ["", "_1", "_2"];

const ProductDetailItem = ({
  product,
  handleAddOnModifier,
  handleRemoveOnModifier,
  handleIncrementQuantity,
  handleDecrementQuantity,
  instructionOnchange,
}: ProductDetailItemProps) => {
  const { id, productName, quantity, addOns, removeOns } = product;

  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const img = e.currentTarget;
    img.src = require("assets/images/logo_image.png");
  };

  const MemorizedSliderItem = useMemo(() => {
    return ImageItems.map((index) => {
      const imagePath = `./contents/images/products/${productName}${index}.jpg`;
      return (
        <Grid className="slide-item">
          <img src={imagePath} alt="" onError={handleError} />
        </Grid>
      );
    });
  }, [productName]);

  return (
    <Grid className="product-detail-item" display={"flex"} direction={"column"}>
      <Grid>
        <Slider {...ProductDetailSliderSetting}>{MemorizedSliderItem}</Slider>
      </Grid>
      <Grid style={{ paddingLeft: "8px" }}>
        <Typography variant="h6">{productName}</Typography>
      </Grid>
      <Grid>
        <QuantityControl
          id={id}
          quantity={quantity}
          handleIncrement={handleIncrementQuantity}
          handleDecrement={handleDecrementQuantity}
        />
      </Grid>
      <Grid>
        {addOns && addOns?.length > 0 && (
          <ExpandCard
            title="Add On"
            items={addOns}
            onAddItem={handleAddOnModifier}
          />
        )}
      </Grid>
      <Grid>
        {removeOns && removeOns?.length > 0 && (
          <ExpandCard
            title="Remove On"
            items={removeOns}
            onAddItem={handleRemoveOnModifier}
          />
        )}
      </Grid>
      <Grid>
        <TextareaAutosize
          value={product.instruction}
          placeholder="Instruction..."
          minRows={3}
          maxRows={3}
          style={{ width: "100%", border: 0, paddingTop: "8px" }}
          onChange={instructionOnchange}
        ></TextareaAutosize>
      </Grid>
    </Grid>
  );
};

export default ProductDetailItem;
