import { Grid, Paper, Typography, Button, Card } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import "./styles.scss";
import Utils from "utils";

interface FooterProps {
  quantityTotal: number;
  amount: number;
  isCheckOut: boolean;
  handleCheckOut?: any;
  handleShowCart?: any;
  handlePayment?: any;
}

const Footer = ({
  quantityTotal,
  amount,
  isCheckOut,
  handleCheckOut,
  handleShowCart,
  handlePayment,
}: FooterProps) => {
  return (
    <Grid container className="footer">
      <Paper
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 9999,
        }}
      >
        {!isCheckOut && (
          <Grid
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Card className="view-cart" onClick={handleShowCart}>
              <Grid
                className="card-content"
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid display={"flex"} className="icon-cart">
                  <ShoppingCartIcon />
                  <Typography
                    variant="caption"
                    component={"div"}
                    className="quantity-total"
                  >
                    {quantityTotal}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography className="total-amount" variant="body2">
                    {Utils.formatCurrency(amount)}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
            <Card className="checkout-button">
              <Grid>
                <Button
                  size="small"
                  fullWidth
                  variant="contained"
                  disabled={amount === 0}
                  onClick={handleCheckOut}
                >
                  Checkout
                </Button>
              </Grid>
            </Card>
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};

export default Footer;
