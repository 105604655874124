import { TAppState } from "../type";

export const selectCarts = (state: TAppState) => state?.Cart?.carts ?? [];
export const selectOpenCart = (state: TAppState) => state?.Cart?.isOpenCart;
export const selectIsLoading = (state: TAppState) => state?.Data?.isLoading;
export const selectProducts = (state: TAppState) =>
  state?.Data?.products ?? [];
  export const selectOpenProductDetail = (state: TAppState) => state?.ProductDetail?.isOpenProductDetail;
export const selectCategories = (state: TAppState) =>
  state?.Data?.categories ?? [];
export const selectModifiers = (state: TAppState) =>
  state?.Data?.modifiers ?? [];
export const selectProductDetail = (state: TAppState) =>
  state?.ProductDetail.product;
export const selectCustomModal = (state: TAppState) => state.CustomModal;
export const selectTable = (state: TAppState) => state.Cart.tableCode;
