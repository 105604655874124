import { Grid, Typography } from "@mui/material";

interface QuestionCardProps {
  title: string;
  content: string;
  showHalal?: boolean;
}

const QuestionCard = ({ title, content, showHalal }: QuestionCardProps) => (
  <Grid className="question-card-container">
    <Grid container>
      <Grid item lg={9} sm={9} xs={12}>
        <Typography className="question-card-title">{title}</Typography>
      </Grid>
      <Grid item lg={3} sm={3} xs={12} className="halal">
        {showHalal && <img src={require("assets/images/hala.png")} alt="" />}
      </Grid>
    </Grid>
    <Typography className="question-card-content">{content}</Typography>
  </Grid>
);

export default QuestionCard;
