import { useEffect, useState } from "react";
import "./_styles.scss";
import { CircularProgress, Container, Grid } from "@mui/material";
import SideBarNavigation from "../common/sideBarNavigation";
import StoreInfo from "components/common/storeInfo";
import ProductList from "components/productList";
import Footer from "components/footer";
import Utils from "utils";
import CartComponent from "components/cart/index";
import ProductDetail from "components/productDetail";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCarts,
  selectCategories,
  selectCustomModal,
  selectIsLoading,
  selectModifiers,
  selectOpenCart,
  selectOpenProductDetail,
  selectProducts,
  selectTable,
} from "store/product/selector";
import {
  addTableCode,
  clearAll,
  closeModal,
  fetchDataStart,
  loadCart,
  openCart,
  openModal,
  openProductDetail,
  setProduct,
} from "store/product/slice";
import { ProductDetailModel } from "models/productDetail";
import { Modifier } from "models/modifier";
import { useEffectOnce } from "utils/common";
import { useSearchParams, useNavigate } from "react-router-dom";
import { CART_STATUS, MODIFIER_TYPE } from "constants/index";
import { getItemLocalStorage, removeItemLocalStorage } from "storage";
import { CartStateType } from "store/product/state";
import { CustomModal } from "components/common/modal";
import SearchText from "components/common/searchText";

const Product = () => {
  const dispatch = useDispatch();
  const [isCheckOut, setIsCheckOut] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();
  const key = searchParams.get("key") ?? "";

  useEffectOnce(() => {
    if (!Utils.hasKey(key)) {
      navigate("/");
    } else {
      searchParams.delete("key");
      setSearchParams(searchParams);
    }

    const table: string = searchParams.get("table") ?? "";
    if (table) {
      dispatch(addTableCode(table));
      const currentCartOrder: CartStateType = getItemLocalStorage(table);
      if (
        currentCartOrder &&
        currentCartOrder.status === CART_STATUS.ORDERING
      ) {
        dispatch(
          openModal({
            title: "Announcement",
            content: `Table ${table} has not been ordered. Would you like to continue?`,
          })
        );
      }
    }
    dispatch(fetchDataStart());
  });

  useEffect(() => {
    const onScroll = () => {
      const menu = document.querySelector(".order-header");
      if (window.scrollY > 100) {
        menu?.classList.add("fixed-header");
      } else {
        menu?.classList.remove("fixed-header");
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [dispatch]);

  const isLoading = useSelector(selectIsLoading);
  const categories = useSelector(selectCategories);
  const modifiers = useSelector(selectModifiers);
  const products = useSelector(selectProducts);
  const carts = useSelector(selectCarts);
  const customModal = useSelector(selectCustomModal);
  const tableCode = useSelector(selectTable);
  const isOpenCart = useSelector(selectOpenCart);
  const isOpenProductDetail = useSelector(selectOpenProductDetail);

  const initState = (productId: number): ProductDetailModel => {
    const product = products?.find((x) => x.id === productId);
    const existCart = carts?.find((x) => x.id === productId);
    const addOns = modifiers
      ?.filter(
        (mod: Modifier) =>
          mod.modifier_id === MODIFIER_TYPE.ADD_ON &&
          product?.modifier_ids.some((modifierId) => modifierId === mod.id)
      )
      .map((x) => {
        const item: Modifier = {
          id: x.id,
          isChecked: false,
          modifier_id: x.modifier_id,
          name: x.name,
          quantity: x.quantity,
          price: x.price,
          type: x.type,
        };
        return item;
      });
    const removeOns = modifiers
      ?.filter(
        (mod: Modifier) =>
          mod.modifier_id === MODIFIER_TYPE.REMOVE_ON &&
          product?.modifier_ids.some((z) => z === mod.id)
      )
      .map((x) => {
        const item: Modifier = {
          id: x.id,
          isChecked: false,
          modifier_id: x.modifier_id,
          name: x.name,
          quantity: x.quantity,
          price: x.price,
          type: x.type,
        };
        return item;
      });

    const model: ProductDetailModel = {
      id: product?.id ?? 0,
      productName: product?.name ?? "",
      price: product?.price ?? 0,
      quantity: existCart ? existCart.quantity : 1,
      addOns: existCart ? existCart.addOns : addOns,
      removeOns: existCart ? existCart.removeOns : removeOns,
      instruction: existCart?.instruction ?? "",
    };
    return model;
  };

  const handleShowProductDetail = (productId: number): void => {
    const prod = products.find((x) => x.id === productId);
    if (prod) {
      const request: ProductDetailModel = initState(prod.id);
      dispatch(setProduct(request));
    }
  };

  const handleShowCart = () => {
    if (carts.length) {
      dispatch(openCart(true));
    }
  };

  const handleCartClose = () => {
    dispatch(openCart(false));
    setIsCheckOut(false);
  };

  const handleProductDetailClose = () => {
    dispatch(openProductDetail(false));
  };

  const handleCheckOut = () => {
    setIsCheckOut(true);
    dispatch(openCart(true));
  };

  const handlePayment = () => {
    setIsCheckOut(false);
    dispatch(openCart(false));
    if (tableCode) {
      removeItemLocalStorage(tableCode);
    }
    dispatch(clearAll());
    //handle call api payment
  };

  const quantityTotal = Utils.recalculateTotalQuantity(carts);
  const totalAmount = Utils.recalculateTotalAmount(carts);

  const handleOnCloseModal = () => {
    if (tableCode) {
      removeItemLocalStorage(tableCode);
    }
    dispatch(closeModal());
  };

  const handleContinue = () => {
    if (tableCode) {
      const currentCartOrder: CartStateType = getItemLocalStorage(tableCode);
      dispatch(loadCart(currentCartOrder));
    }
    dispatch(closeModal());
  };

  return (
    <Grid
      className="home-content"
      item
      display={"flex"}
      justifyContent={"center"}
      direction={"column"}
    >
      <SideBarNavigation items={categories} tableCode={tableCode} />
      <Container>
        <StoreInfo />
        <SearchText />
        <ProductList
          productItems={products}
          categoryItems={categories}
          handleShowProductDetail={handleShowProductDetail}
        />
      </Container>
      <CartComponent
        isCartOpen={isOpenCart}
        isCheckOut={isCheckOut}
        cartItems={carts}
        amount={totalAmount}
        handleCheckOut={handleCheckOut}
        handlePayment={handlePayment}
        onClose={handleCartClose}
      />
      <ProductDetail
        isOpen={isOpenProductDetail}
        onClose={handleProductDetailClose}
      />
      {!isOpenProductDetail && !isOpenCart && (
        <Footer
          isCheckOut={isCheckOut}
          quantityTotal={quantityTotal}
          amount={totalAmount}
          handleShowCart={handleShowCart}
          handleCheckOut={handleCheckOut}
          handlePayment={handlePayment}
        />
      )}
      {isLoading && (
        <Grid container className="loader">
          <CircularProgress color="success" />
        </Grid>
      )}
      {customModal.isOpen && (
        <CustomModal
          isOpen={customModal.isOpen}
          title={customModal.title}
          content={customModal.content}
          handleContinue={handleContinue}
          handleClose={handleOnCloseModal}
        />
      )}
    </Grid>
  );
};

export default Product;
