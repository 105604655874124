import { CART_STATUS } from "constants/index";
import { Cart } from "../../models/cart";
import { ProductDetailModel } from "../../models/productDetail";
import { Product } from "models/product";
import { Category } from "models/category";
import { Modifier } from "models/modifier";

export type CartStateType = {
  tableCode: string,
  isLoading: boolean;
  isOpenCart: boolean;
  status: string,
  carts: Cart[];
};

export type DataStateType = {
  isLoading: boolean | false;
  products?: Product[];
  originalProducts?: Product[];
  categories?: Category[];
  modifiers?: Modifier[];
  error?: string;
};

export type ProductDetailStateType = {
  product: ProductDetailModel;
  isOpenProductDetail: boolean;
};

export type CustomModalStateType = {
  isOpen: boolean,
  title?: string,
  content?: string
}

export const initialCartState: CartStateType = {
  tableCode: "",
  isLoading: false,
  isOpenCart: false,
  status: CART_STATUS.ORDERING,
  carts: [],
};

export const initialDataState: DataStateType = {
  isLoading: false,
};

export const initialProductDetailState: ProductDetailStateType = {
  product: {
    id: 0,
    instruction: "",
    productName: "",
    price: 0,
    quantity: 0,
    addOns: [],
    removeOns: [],
  },
  isOpenProductDetail: false
};
