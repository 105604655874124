import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Container, Grid } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "45%",
  left: 10,
  right: 10,
  bgcolor: "background.paper",
  p: 4,
};

interface CustomModalProps {
  isOpen: boolean;
  title?: string;
  content?: string;
  handleContinue: any;
  handleClose: any;
}

export const CustomModal = ({
  isOpen,
  title,
  content,
  handleContinue,
  handleClose,
}: CustomModalProps) => {
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Container>
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {content}
            </Typography>
            <Grid display={"flex"} gap={"8px"} justifyContent={"flex-end"}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#b2474e",
                  textTransform: "none",
                  fontSize: "14px",
                }}
                onClick={handleContinue}
              >
                Continue
              </Button>
              <Button
                variant="outlined"
                style={{
                  textTransform: "none",
                  fontSize: "14px",
                }}
                onClick={handleClose}
              >
                Skip
              </Button>
            </Grid>
          </Box>
        </Container>
      </Modal>
    </div>
  );
};
