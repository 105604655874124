import { Grid } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { Sliders } from "constants/index";
import "./styles.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Slider = () => {
  return (
    <Grid className="slider">
      <Carousel
        animation="fade"
        duration={5000}
        stopAutoPlayOnHover={true}
        NextIcon={<ArrowForwardIcon />}
        PrevIcon={<ArrowBackIcon />}
        className="carousel"
      >
        {Sliders.map((slider) => {
          return (
            <div>
              <img
                src={`/contents/images/sliders/${slider}.png`}
                alt="slide item"
                className="slider-banner-image"
              />
            </div>
          );
        })}
      </Carousel>
    </Grid>
  );
};

export default Slider;