import { Grid, Typography } from "@mui/material";
import { Product } from "models/product";
import "./_styles.scss";
import ProductItem from "./productItem";
import { Category } from "models/category";

interface ProductProps {
  categoryItems?: Category[];
  productItems?: Product[];
  handleShowProductDetail?: any;
}

const ProductList = ({
  productItems,
  categoryItems,
  handleShowProductDetail,
}: ProductProps) => {
  const renderItem = () => {
    if (productItems?.length === 0) {
      return <Grid className="product-list-empty">There are no result.</Grid>;
    }

    return categoryItems?.map((category) => {
      const productOfCategories = productItems?.filter(
        (x) => x.category_id === category.id
      );
      if (productOfCategories?.length === 0) {
        return null;
      }
      return (
        <Grid className="category-group">
          <Grid className="category">
            <Typography
              id={`${category.id}`}
              variant="h5"
              style={{ marginTop: "8px" }}
              component={"a"}
            >
              {category.name.trim()}
            </Typography>
          </Grid>
          <Grid container display={"flex"} spacing={1} direction={"row"}>
            {productOfCategories
              ?.filter((x) => x.category_id === category.id)
              .map((product) => {
                return (
                  <Grid
                    item
                    className="product-item"
                    key={product.id}
                    xs={12}
                    lg={4}
                    sm={12}
                    md={4}
                  >
                    <ProductItem
                      product={product}
                      handleShowProductDetail={handleShowProductDetail}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      );
    });
  };
  return <Grid className="product-list">{renderItem()}</Grid>;
};

export default ProductList;
