import { Grid, Typography } from "@mui/material";
import FacebookIcon from "assets/images/facebook.svg";
import InstagramIcon from "assets/images/instagram.svg";
import LocationIcon from "assets/images/location.svg";
import TimeIcon from "assets/images/time-circle.svg";
import StoreInfoCardItem from "./StoreInfoCardItem";
import WorkingTimeItem from "./WorkingTimeItem";
import SocialLinked from "./SocialLinked";

interface StoreInfoCardProps {
  storeName: string;
  address: string;
  workingTime1: string;
  workingTime2: string;
  workingTimeText1: string;
  workingTimeText2: string;
  direction: string;
  facebookUrl: string;
  instagramUrl: string;
}

const StoreInfoCard = ({
  storeName,
  address,
  workingTimeText1,
  workingTime1,
  workingTimeText2,
  workingTime2,
  direction,
  facebookUrl,
  instagramUrl,
}: StoreInfoCardProps) => {
  return (
    <Grid className="store-info-container">
      <Typography className="store-name">{storeName}</Typography>
      <StoreInfoCardItem icon={LocationIcon} title={address} />
      <WorkingTimeItem
        icon={TimeIcon}
        workingTime1={workingTime1}
        workingTime2={workingTime2}
        workingTimeText1={workingTimeText1}
        workingTimeText2={workingTimeText2}
      />
      <SocialLinked
        facebookIcon={FacebookIcon}
        facebookUrl={facebookUrl}
        instagramIcon={InstagramIcon}
        instagramUrl={instagramUrl}
        directionUrl={direction}
      />
    </Grid>
  );
};

export default StoreInfoCard;
