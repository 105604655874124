import StoreInformation from "./components/StoreInformation";
import Header from "./components/Header";
import Slider from "./components/Slider";
import Sliding from "./components/Sliding";
import WrapContent from "./components/WrapContent";
import Footer from "./components/Footer";

const Home = () => {
  return (
    <div>
      <Header />
      <Slider />
      <Sliding />
      <WrapContent />
      <StoreInformation />
      <Footer />
    </div>
  );
};

export default Home;
