import { Grid } from "@mui/material";
import Point from "assets/images/point.svg";

const SlidingItem = () => {
  return (
    <Grid
      className="sliding-container"
      display={"flex"}
      gap={4}
    >
      <Grid>
        <img src={Point} alt="" />
      </Grid>
      <Grid>
        <img src={require("assets/images/sliding_1.png")} alt="" />
      </Grid>
      <Grid>
        <img src={Point} alt="" />
      </Grid>
      <Grid>
        <img src={require("assets/images/sliding_2.png")} alt="" />
      </Grid>
    </Grid>
  );
};

export default SlidingItem;
