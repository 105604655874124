import { Grid, IconButton, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

interface QuantityControlProps {
  id: number;
  quantity: number;
  handleDecrement?: any;
  handleIncrement?: any;
}

const QuantityControl = ({
  id,
  quantity,
  handleIncrement,
  handleDecrement,
}: QuantityControlProps) => {
  return (
    <Grid
      className="quantity-control"
      display={"flex"}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Grid>
        <IconButton onClick={() => handleDecrement(id)}>
          <RemoveCircleOutlineIcon fontSize="medium" />
        </IconButton>
      </Grid>
      <Grid
        alignItems={"center"}
        justifyContent={"center"}
        alignContent={"center"}
      >
        <Typography variant="body1">{quantity}</Typography>
      </Grid>
      <Grid>
        <IconButton onClick={() => handleIncrement(id)}>
          <AddCircleOutlineIcon fontSize="medium" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default QuantityControl;
