import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import reducers from "./reducers";
import rootSaga from "./product/saga";

const configureAppStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = (getDefaultMiddleware: any): any =>
    getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware);

  const store = configureStore({
    reducer: reducers,
    middleware,
  });
  sagaMiddleware.run(rootSaga);

  return store;
};

export const store = configureAppStore();
