import { Grid, Typography } from "@mui/material";
import { Product } from "models/product";
import Utils from "utils";

interface ProductItemProps {
  product: Product;
  handleShowProductDetail?: any;
}

const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
  const img = e.currentTarget;
  img.src = require("assets/images/logo_image.png");
};

const ProductItem = ({
  product,
  handleShowProductDetail,
}: ProductItemProps) => {
  return (
    <Grid className="card-item">
      <Grid className="product-image-group">
        <img
          className="product-image"
          onError={handleError}
          src={`./contents/images/products/${product.name
            .trim()
            .replace("/", "")}.jpg`}
          alt="Product name"
          onClick={() => handleShowProductDetail(product.id)}
        />
      </Grid>
      <Grid className="product-info">
        <Grid
          display={"flex"}
          justifyContent={"space-between"}
          direction={"column"}
        >
          <Grid>
            <Typography className="product-name" variant="h6" component="div">
              {product.name.trim()}
            </Typography>
            <Typography
              className="product-price"
              color="textSecondary"
              gutterBottom
            >
              {Utils.formatCurrency(product.price)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductItem;
