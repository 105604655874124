import { Grid } from "@mui/material";

interface SocialLinkedProps {
  facebookIcon: any;
  facebookUrl: string;
  instagramIcon: any;
  instagramUrl: string;
  directionUrl: string;
}

const SocialLinked = ({
  facebookIcon,
  facebookUrl,
  instagramIcon,
  instagramUrl,
  directionUrl,
}: SocialLinkedProps) => {
  return (
    <Grid display={"flex"} justifyContent={"space-between"}>
      <Grid display={"flex"} gap={"16px"}>
        <a href={facebookUrl} target="_blank" rel="noreferrer">
          <img width={24} height={24} src={facebookIcon} alt="" />
        </a>
        <a href={instagramUrl} target="_blank" rel="noreferrer">
          <img width={24} height={24} src={instagramIcon} alt="" />
        </a>
      </Grid>
      <Grid>
        <a
          href={directionUrl}
          target="_blank"
          className="get-direction-link"
          rel="noreferrer"
        >
          Get direction
        </a>
      </Grid>
    </Grid>
  );
};

export default SocialLinked;
