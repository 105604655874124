import Marquee from "react-fast-marquee";
import "./styles.scss";
import SlidingItem from "./components/SlidingItem";

const Sliding = () => {
  return (
    <Marquee className="sliding" speed={110}>
      <SlidingItem key={1} />
      <SlidingItem key={2} />
      <SlidingItem key={3} />
    </Marquee>
  );
};

export default Sliding;
