import { Button, Grid, Link, Typography } from "@mui/material";
import { CategorySliderSetting } from "constants/index";
import { Category } from "models/category";
import Slider from "react-slick";
import "./styles.scss";
import { useState } from "react";

interface SideBarNavigationProps {
  items: Category[];
  tableCode: string;
}

const SideBarNavigation = ({ items, tableCode }: SideBarNavigationProps) => {
  const [sliderActive, setSliderActive] = useState<number>(items[0]?.id ?? 0);

  const itemOnSelect = (item: number) => {
    setSliderActive(item);
    var element = document.getElementById(item.toString());
    if (!element) {
      return;
    }
    var headerOffset = 300;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  
    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });
  };

  return (
    <Grid className="order-header">
      <Grid display={"flex"} justifyContent={"center"}>
        <Grid
          display={"flex"}
          justifyContent={"center"}
          style={{
            height: "100px",
            width: "180px",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Ordering for table {tableCode}
          </Typography>
          <img
            width={70}
            height={70}
            src={require("assets/images/logo_image.png")}
            alt=""
          />
        </Grid>
      </Grid>
      <Grid className="nav-bar">
        <Slider {...CategorySliderSetting}>
          {items?.map((item) => {
            return (
              <Grid className="slide-item">
                <Link
                  className={`text-dark ${
                    sliderActive === item.id ? "active" : ""
                  }`}
                >
                  <Button onClick={() => itemOnSelect(item.id)}>
                    <Typography variant="caption">{item.name}</Typography>
                  </Button>
                </Link>
              </Grid>
            );
          })}
        </Slider>
      </Grid>
    </Grid>
  );
};

export default SideBarNavigation;
