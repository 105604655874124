import { Container, Grid, Typography } from "@mui/material";
import "./styles.scss";
import StoreInfoCard from "./components/StoreInfoCard";

const StoreInformation = () => {
  return (
    <Grid id="Waitlist" className="store-information-container">
      <Container maxWidth="lg">
        <Typography className="store-information-title">
          It's time for some Bingsoo!
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12} sm={12}>
            <StoreInfoCard
              storeName={"Bingsoo Springvale"}
              address={"327 Springvale Road, Springvale Vic 3171"}
              workingTimeText1={"Monday to Thursday"}
              workingTime1={"4:00pm - 11:00pm"}
              workingTimeText2={"Friday to Sunday"}
              workingTime2={"2:00pm - 11:00pm"}
              direction={"https://maps.app.goo.gl/zuWT9RdBXX5jcJGC6?g_st=ic"}
              facebookUrl={"https://www.facebook.com/bingsoo.au"}
              instagramUrl={"https://www.instagram.com/bingsoo.au"}
            />
          </Grid>
          <Grid item md={6} xs={12} sm={12}>
            <StoreInfoCard
              storeName={"Bingsoo Melbourne CBD"}
              address={"260 La Trobe Street, Melbourne Vic 3000"}
              workingTimeText1={"Sunday to Wednesday"}
              workingTime1={"11:00am - 11:00pm"}
              workingTimeText2={"Thursday to Saturday"}
              workingTime2={"11:00am - 1:00am"}
              direction={"https://maps.app.goo.gl/8eyJd51cgamQzifw7?g_st=ic"}
              facebookUrl={"https://www.facebook.com/bingsoo.au"}
              instagramUrl={"https://www.instagram.com/bingsoo.au"}
            />
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default StoreInformation;
