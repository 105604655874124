import { Button, Container, Grid, Typography } from "@mui/material";
import "./styles.scss";
import QuestionContent from "./components/QuestionContent";
import QuestionCard from "./components/QuestionCard";

const WrapContent = () => {
  return (
    <Grid id="FAQs" className="wrap-content">
      <Grid className="welcome-container">
        <Container maxWidth="lg" className="welcome-content">
          <Grid container>
            <Grid item md={6} xs={12} sm={12} lg={6} className="left-container">
              <Grid gap={20}>
                <Typography className="title">
                  Welcome to <br />
                  Your Newest <br />
                  Dessert Destination!
                </Typography>
                <Typography className="sub-title">
                  Our shop offers a sweet escape from the daily grind, with a
                  menu full of delicious treats to satisfy any sweet tooth!
                </Typography>
              </Grid>
              <Button
                className="order-now-button"
                onClick={() => {
                  window.location.href = "/contents/menu/BingsooMenu.pdf";
                }}
              >
                Order Now!
              </Button>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sm={12}
              lg={6}
              className="right-container"
            >
              <img
                className="welcome-logo"
                src={require("assets/images/welcome-logo.png")}
                alt=""
              />
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid className="question-container">
        <Container maxWidth="lg">
          <Grid container className="question-content">
            <Grid
              item
              lg={6}
              md={12}
              xs={12}
              sm={12}
              className="question-logo-container"
            >
              <img src={require("assets/images/spinner.png")} alt="" />
            </Grid>
            <Grid
              item
              lg={6}
              sm={12}
              md={12}
              className="question-content-container"
              display={"flex"}
              flexDirection={"column"}
              gap={"64px"}
            >
              <QuestionContent
                title={"What is Bingsoo?"}
                content={
                  "It's a Korean shaved ice sensation that you'll love! In a country where summers are notorious for their intense heat, we can appreciate any chance to cool down with a cold dessert - and that's where Bingsoo comes in! With an array of rich toppings, our dessert is ideal for when you desire something sweet and icy without having to freeze yourself solid!"
                }
              />
              <QuestionContent
                title={"What's on the menu?"}
                content={
                  "We currently offer 8 flavours of bingsoo, along with various options for Korean Thick Toasts and mini pancakes that are sure to leave your taste buds satisfied!"
                }
              />
              <Grid textAlign={"center"}>
                <Button
                  className="click-for-the-menu-button"
                  endIcon={
                    <img src={require("assets/images/play.png")} alt="" />
                  }
                  onClick={() =>
                    (window.location.href = "/contents/menu/BingsooMenu.pdf")
                  }
                >
                  Click for the Menu
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className="question-card" spacing={2}>
            <Grid item md={6}>
              <QuestionCard
                title={"Do you accommodate large groups?"}
                content={
                  "Pro tip: Bingsoo is the perfect dessert to share with a group of friends. Our venue can handle squads of up to 16 people, making it a great spot for birthdays, anniversaries and everything in between. Be sure to book in advance!"
                }
              />
            </Grid>
            <Grid item md={6}>
              <QuestionCard
                title={"Do you cater for dietary requirements?"}
                content={
                  "Our menu is thoughtfully crafted to be enjoyed by everyone, including those following a vegetarian diet. You won't have to sacrifice taste or your dietary preferences to indulge in our scrumptious shaved ice creations!"
                }
                showHalal
              />
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default WrapContent;
