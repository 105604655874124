import { Grid, Typography } from "@mui/material";

interface QuestionContentProps {
  title: string;
  content: string;
}

const QuestionContent = ({ title, content }: QuestionContentProps) => {
  return (
    <Grid className="question-content-item">
      <Typography className="question-item-title">{title}</Typography>
      <Typography component="p" className="question-item-content">
        {content}
      </Typography>
    </Grid>
  );
};

export default QuestionContent;
