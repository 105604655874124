import { Grid, Typography } from "@mui/material";

interface StoreInfoCardItemProps {
  icon: any;
  title: string;
}

const StoreInfoCardItem = ({ icon, title }: StoreInfoCardItemProps) => {
  return (
    <Grid className="store-info-card-item-container" display={"flex"} gap={"16px"}>
      <img width={24} height={24} src={icon} alt="" />
      <Typography className="store-info-card-item-title">{title}</Typography>
    </Grid>
  );
};

export default StoreInfoCardItem;
