import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  fetchDataSuccess,
  fetchDataFailure,
  fetchDataStart
} from "./slice";
import getProducts from "services/api";
import { ApiResponse } from "models/apiResponse";

function* fetchData() {
  try {
    const data: ApiResponse = yield call(getProducts);
    yield put(fetchDataSuccess(data));
  } catch {
    yield put(fetchDataFailure());
  }
}

function* dataSaga() {
  yield takeLatest(fetchDataStart.type, fetchData);
}

function* rootSaga() {
  yield all([dataSaga()]);
}

export default rootSaga;
