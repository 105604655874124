import { Grid, IconButton, Typography } from "@mui/material";
import { Cart } from "models/cart";
import Utils from "utils";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useDispatch } from "react-redux";
import { decrementQuantity, incrementQuantity, removeItem } from "store/product/slice";
import DeleteIcon from "@mui/icons-material/Delete";

interface CartItemProps {
  cartItem: Cart;
}

const CartItem = ({ cartItem }: CartItemProps) => {
  const dispatch = useDispatch();

  const handleIncrementQuantity = (productId: number) => {
    dispatch(incrementQuantity(productId));
  };

  const handleDecrementQuantity = (productId: number) => {
    dispatch(decrementQuantity(productId));
  };

  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const img = e.currentTarget;
    img.src = require("assets/images/logo_image.png");
  };

  const handleDeleteCartItem = (productId: number) => {
    dispatch(removeItem(productId));
  }

  return (
    <Grid container className="product-item">
      <Grid item xs={3} sm={4} md={4}>
        <img
          className="product-image"
          src={`./contents/images/products/${cartItem.productName}.jpg`}
          alt="Product name"
          onError={handleError}
        />
      </Grid>
      <Grid item className="product-info" xs={8} sm={7} md={5}>
        <Grid
          display={"flex"}
          justifyContent={"space-between"}
          direction={"column"}
        >
          <Grid>
            <Typography className="product-name" variant="h6" component="div">
              {cartItem.productName}
            </Typography>
            <Typography className="add-on" variant="caption">
              +
              {cartItem.addOns
                ?.filter((y) => y.isChecked)
                ?.map((x) => x.name)
                .join(", ")}
            </Typography>
            <Typography className="remove-on" variant="caption" component="div">
              -
              {cartItem.removeOns
                ?.filter((y) => y.isChecked)
                .map((x) => x.name)
                .join(", ")}
            </Typography>
            <Typography
              className="product-price"
              color="textSecondary"
              gutterBottom
            >
              {Utils.formatCurrency(cartItem.price)}
            </Typography>
          </Grid>
          <Grid
            className="add-to-cart"
            display={"flex"}
            direction={"row"}
            alignItems={"center"}
          >
            <Grid>
              <IconButton onClick={() => handleDecrementQuantity(cartItem.id)}>
                <RemoveCircleOutlineIcon fontSize="medium" />
              </IconButton>
            </Grid>
            <Grid
              alignItems={"center"}
              justifyContent={"center"}
              alignContent={"center"}
            >
              <Typography variant="body1">{cartItem.quantity}</Typography>
            </Grid>
            <Grid>
              <IconButton onClick={() => handleIncrementQuantity(cartItem.id)}>
                <AddCircleOutlineIcon fontSize="medium" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid display={"flex"} alignSelf={"flex-end"}>
            <IconButton className="cart-delete-button" onClick={() => handleDeleteCartItem(cartItem.id)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CartItem;
