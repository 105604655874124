export const CategorySliderSetting = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};
export const ProductDetailSliderSetting = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
export const BannerSliderSetting = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
};
export const Sliders = ["banner_0", "banner_1", "banner_2"];

export const NS_CART = "Cart";
export const NS_DATA = "Data";
export const NS_PRODUCT_DETAIL = "ProductDetail";
export const NS_CUSTOM_MODAL = "CustomModal";
export const CART_STATUS = {
  ORDERING: "ORDERING",
  SUCCESS: "SUCCESS",
};
export const MODIFIER_TYPE = {
  ADD_ON: 5782,
  REMOVE_ON: 7694,
};

export const KEY_ORDER_PAGE: string =
  "ba7816bf8f01cfea414140de5dae2223b00361a396177a9cb410ff61f20015ad";
