import {
  Button,
  Card,
  CardContent,
  Container,
  Drawer,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import "./styles.scss";
import CloseIcon from "@mui/icons-material/Close";
import ProductDetailItem from "./components/ProductDetailItem";
import { Cart } from "models/cart";
import Utils from "utils";
import { useDispatch, useSelector } from "react-redux";
import { selectCarts, selectProductDetail } from "store/product/selector";
import {
  addItem,
  addOn,
  decrementQuantityProductDetail,
  incrementQuantityProductDetail,
  instructionOnChange,
  removeItem,
  removeOn,
  updateItem,
} from "store/product/slice";

interface ProductDetailProps {
  isOpen: boolean;
  onClose?: any;
}

const ProductDetail = ({ isOpen, onClose }: ProductDetailProps) => {
  const dispatch = useDispatch();

  const handleAddOnModifier = (modifierId: number) => {
    dispatch(addOn(modifierId));
  };

  const handleRemoveOnModifier = (modifierId: number) => {
    dispatch(removeOn(modifierId));
  };

  const handleIncrementQuantity = () => {
    dispatch(incrementQuantityProductDetail());
  };

  const handleDecrementQuantity = () => {
    dispatch(decrementQuantityProductDetail());
  };

  const instructionOnchange = (e: React.FormEvent<HTMLInputElement>) => {
    dispatch(instructionOnChange(e.currentTarget.value));
  };

  const product = useSelector(selectProductDetail);
  const carts = useSelector(selectCarts);
  const totalAmountAddOns =
    product.addOns
      ?.filter((x) => x.isChecked)
      .map((z) => z.price)
      .reduce((total, value) => total + value, 0) ?? 0;
  const totalAmount = Utils.formatCurrency(
    product.price * product.quantity + totalAmountAddOns
  );

  const onAddToCart = () => {
    if (product.quantity === 0) {
      dispatch(removeItem(product.id));
    }
    const cart: Cart = {
      id: product.id,
      productName: product.productName,
      price: product.price,
      quantity: product.quantity,
      addOns: product.addOns,
      removeOns: product.removeOns,
      instruction: product.instruction,
    };

    const existCart = carts.find((x) => x.id === product.id);
    if (existCart) {
      dispatch(updateItem(cart));
    } else {
      dispatch(addItem(cart));
    }
    onClose();
  };

  return (
    <Container>
      <Drawer
        className="product-detail-container"
        anchor="right"
        open={isOpen}
        onClose={onClose}
        transitionDuration={500}
      >
        <Card className="product-detail">
          <CardContent>
            <Grid display={"flex"} direction={"column"}>
              <Grid
                className="product-header"
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Grid display={"flex"} alignItems={"center"}>
                  <Typography variant="h6" gutterBottom>
                    Add to cart
                  </Typography>
                </Grid>
                <Grid>
                  <IconButton onClick={onClose}>
                    <CloseIcon fontSize="medium" />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid>
                <ProductDetailItem
                  product={product}
                  handleAddOnModifier={handleAddOnModifier}
                  handleRemoveOnModifier={handleRemoveOnModifier}
                  handleIncrementQuantity={handleIncrementQuantity}
                  handleDecrementQuantity={handleDecrementQuantity}
                  instructionOnchange={instructionOnchange}
                />
                <Grid className="product-detail-footer">
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#9faa7b",
                      textTransform: "none",
                      fontSize: "14px",
                      borderRadius: 0,
                    }}
                    onClick={onAddToCart}
                  >
                    Add to cart ({totalAmount})
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Drawer>
    </Container>
  );
};

export default ProductDetail;
