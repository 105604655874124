import { Grid, Typography } from "@mui/material";

interface WorkingTimeItemProps {
  icon: any;
  workingTime1: string;
  workingTime2: string;
  workingTimeText1: string;
  workingTimeText2: string;
}

const WorkingTimeItem = ({
  icon,
  workingTime1,
  workingTime2,
  workingTimeText1,
  workingTimeText2,
}: WorkingTimeItemProps) => {
  return (
    <Grid className="working-time-item-container">
      <img width={24} height={24} src={icon} alt="" />
      <Grid container className="working-time-item-content">
        <Grid item className="working-time-item-left">
          <Typography className="working-time-value">
            {workingTimeText1}
          </Typography>
          <Typography className="working-time-value">{workingTime1}</Typography>
        </Grid>
        <Grid item>
          <Typography className="working-time-value">
            {workingTimeText2}
          </Typography>
          <Typography className="working-time-value">{workingTime2}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WorkingTimeItem;
