import { Grid, Typography } from "@mui/material";
import Rating from "@mui/material/Rating";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import "./styles.scss";

const StoreInfo = () => {
  return (
    <Grid
      className="store-info"
      display={"flex"}
      direction={"column"}
      gap={"8px"}
    >
      <Grid display={"flex"} gap={"8px"}>
        <Typography variant="caption">
          <Rating name="simple-controlled" value={4} />
        </Typography>
        <Typography
          variant="caption"
          alignItems={"center"}
          justifyContent={"center"}
        >
          (1,000+ ratings) • $0 Pickup Fee
        </Typography>
      </Grid>
      <Grid display={"flex"} gap={"8px"}>
        <Typography variant="inherit">
          <LocationOnIcon fontSize="small" />
        </Typography>
        <Typography variant="caption">
          327 Springvale Road Springvale, Springvale, VIC, 3171
        </Typography>
      </Grid>
      <Grid display={"flex"} gap={"8px"}>
        <Typography variant="inherit">
          <AccessTimeIcon fontSize="small" />
        </Typography>
        <Typography variant="caption">Opening</Typography>
      </Grid>
    </Grid>
  );
};

export default StoreInfo;
