import { CartStateType } from "store/product/state";

export const setItemLocalStorage = (key: string, value: CartStateType) => {
  if (key) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
};

export const getItemLocalStorage = (key: string) => {
    const value = sessionStorage.getItem(key);
    if (value) {
        return JSON.parse(value);
    }
    return null;
};

export const removeItemLocalStorage = (key: string) => {
    sessionStorage.removeItem(key);
};
