import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Modifier } from "models/modifier";
import "./styles.scss";
import Utils from "utils";
import { useState } from "react";

interface ExpandCardProps {
  title: string;
  items?: Modifier[];
  onAddItem?: any;
}

const ExpandCard = ({ title, items, onAddItem }: ExpandCardProps) => {
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const expandCardItemRender = () => {
    return items?.map((item) => {
      return (
        <Grid
          className="modifier-item"
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          alignContent={"center"}
        >
          <Grid>
            <Typography>{item.name.trim()}</Typography>
          </Grid>
          <Grid display={"flex"} alignItems={"center"}>
            <Grid>
              {item.price !== 0 && (
                <Typography>{Utils.formatCurrency(item.price)}</Typography>
              )}
            </Grid>
            <Grid>
              <Checkbox
                checked={item.isChecked}
                onChange={() => onAddItem(item.id)}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <Card className="cart-item">
      <CardContent className="card-header">
        <Grid display={"flex"} justifyContent={"space-between"}>
          <Typography variant="body1" component="div">
            {title}
          </Typography>
          <Button
            onClick={handleExpandClick}
            endIcon={
              expanded ? (
                <ExpandMoreIcon fontSize="medium" />
              ) : (
                <ExpandLessIcon fontSize="medium" />
              )
            }
            size="small"
            color="primary"
          ></Button>
        </Grid>
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Grid className="card-content" display={"flex"} direction={"column"}>
          {expandCardItemRender()}
        </Grid>
      </Collapse>
    </Card>
  );
};

export default ExpandCard;
