import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { Button, Link } from "@mui/material";
import "./styles.scss";

const pages = [
  { displayName: "Information", href: "#FAQs" },
  { displayName: "Locations", href: "#Waitlist" },
  {
    displayName: "Our menu",
    href: "/contents/menu/BingsooMenu.pdf",
    hasIcon: true,
  },
];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" className="header">
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <IconButton
            sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
            onClick={() => {
              window.location.href = "/";
            }}
          >
            <img src={require("assets/images/logo.png")} alt="" />
          </IconButton>

          <Box
            className="nav-bar-menu"
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
            justifyContent={"flex-end"}
          >
            {pages.map((page) => (
              <Button
                key={page.displayName}
                sx={{
                  my: 2,
                  color: "white",
                  alignContent: "center",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Link href={page.href} className="menu-item">
                  {page.displayName}
                  {page.hasIcon && (
                    <img
                      src={require("assets/images/play.png")}
                      alt=""
                    />
                  )}
                </Link>
              </Button>
            ))}
          </Box>

          <IconButton sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}>
            <img src={require("assets/images/logo.png")} alt="" />
          </IconButton>
          <Box
            className="menu-list"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            justifyContent={"flex-end"}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              style={{ zIndex: 999 }}
              className="icon-button"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem
                key={"Info"}
                onClick={handleCloseNavMenu}
                className="menu-item"
              >
                <Link href="#FAQs" textAlign="center">
                  {"Information"}
                </Link>
              </MenuItem>
              <MenuItem
                key={"Location"}
                onClick={handleCloseNavMenu}
                className="menu-item"
              >
                <Link href="#Waitlist" textAlign="center">
                  {"Locations"}
                </Link>
              </MenuItem>
              <MenuItem
                key={"Our menu"}
                onClick={handleCloseNavMenu}
                className="menu-item"
              >
                <Link
                  href="/contents/menu/BingsooMenu.pdf"
                  className="our-menu-link"
                  textAlign="center"
                >
                  {"Our menu"}
                  <img src={require("assets/images/play.png")} alt="" />
                </Link>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
